.page-home-container {
  &.mobile {
    .homepage-team-title {
      margin-top: 40px;
      text-align: left;
      padding-left: 20px;
    }
  }
  padding-top: 49.56vw;
  .page-home-profile {
    position: absolute;
    width: 30vw;
    top: 4%;
    left: 8%;
    &.pc {
      top: 6%;
    }
    &.mobile {
      width: calc(100vw - 40px);
      left: 20px;
      top: 50vw;
      .title {
        font-size: 26px;
      }
      .desc {
        font-size: 14px;
        margin-top: 10px;
        margin-top: 10px;
      }
    }
    .title {
      color: #3e4028;
      font-family: Francesco;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 90%;
    }
    .desc {
      margin-top: 30px;
      color: #757167;
      font-family: Francesco;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .page-home-desc {
    max-width: 1400px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    &.pc {
      padding: 0;
      margin: 250px calc(50vw - 700px) 0;
    }
    &.page-home-desc-mobile {
      flex-direction: column;
      margin-top: 40px;
      gap: 20px;

      > .img-w {
        display: flex;
        width: 100%;
        justify-content: center;
        > img {
          width: 100%;
          object-fit: contain;
        }
      }

      > .intro-w {
        .juhnz-title {
          font-size: 30px;
          line-height: 40px;
          padding-bottom: 20px;
        }
      }

      .item-wrapper {
        display: block;
        padding-top: 40px;
        .item-desc {
          padding-bottom: 20px;
        }
      }
    }

    margin-top: 250px;
    display: flex;
    gap: 80px;
    align-items: flex-start;
    .juhnz-title {
      color: #3e4028;
      font-family: Francesco;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      border-bottom: 1px solid #beb6ae;
      padding-bottom: 40px;
      line-height: 90%; /* 36px */
    }
  }

  .page-home-mint {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 544px;
    width: 100vw;

    &.page-home-mint-mobile {
      width: 100vw;
      margin-left: 0;
      margin-right: 0;
      margin-top: 60px;
      flex-direction: column-reverse;
      justify-content: start;
      height: auto;
      gap: 0px;

      .juhnz-img {
        width: 100%;
      }

      .home-left-mint {
        width: 100%;
        box-sizing: border-box;
        padding: 40px 20px 40px;
        .home-mint-info {
          width: 100%;
        }
      }
    }

    .home-left-mint {
      background: #181817;
      height: 100%;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .home-mint-info {
        width: 480px;
        .title {
          color: #e1e0e0;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 90%;
        }
        .desc {
          color: #e1e0e0;

          font-family: Francesco;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 90%;
          padding: 40px 0;
          border-bottom: 1px solid #757167;
        }

        button {
          color: #fff;
          &.disabled {
            background: rgba(157, 154, 99, 0.2);
            color: rgba(225, 224, 224, 0.2);
          }
          cursor: pointer;
          appearance: none;
          border: none;
          display: flex;
          width: 154px;
          height: 40px;
          outline: none;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 80px;
          background: #9d9a63;
        }
      }
    }
    .juhnz-img {
      background: url("https://juhnz.web3app.vip/images/juhnz.png")
        no-repeat center;
      flex-shrink: 0;
      background-size: cover;
      width: 50vw;
      height: 544px;
    }
  }

  .item-wrapper {
    display: grid;
    padding-top: 80px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(1fr, 3);
    row-gap: 80px;
    column-gap: 40px;
    &.one {
      grid-template-columns: unset;
    }
    .item-title {
      color: #3d4128;
      font-family: Francesco;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 24px */
    }
    .item-desc {
      margin-top: 20px;
      color: #757167;
      font-family: Francesco;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
    }
  }

  .mint-item {
    display: flex;
    width: 175px;
    margin-top: 20px;
    font-size: 20px;
    justify-content: space-between;
    align-items: center;
    color: #e1e0e0;
    .t {
      color: #757167;
    }
  }

  .homepage-team-title {
    text-align: center;
    margin-top: 180px;
    color: #3e4028;
    font-family: Francesco;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    margin-bottom: 25px;
  }

  .faq-item {
    border-bottom: 1px solid #beb6ae;
    display: flex;
    padding-top: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    box-sizing: border-box;
    padding-bottom: 30px;
    &-title {
      color: #3e4028;
      font-family: Francesco;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 31.2px */
    }
    &-desc {
      color: #757167;
      font-family: Francesco;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
    }
  }
}

.homepage-team-wrapper {
  width: 100%;
  box-sizing: border-box;
  max-width: 1400px;
  padding: 0 20px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-around;

  &.shabi {
    gap: 12px;
  }

  &.pc {
    padding: 0;
    margin-left: calc(50vw - 700px);
  }

  &-mobile {
    margin-left: 0;
    &.person-mobile {
      width: calc(100vw - 40px);
      margin-left: 20px;
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      padding: 0;
      box-sizing: border-box;
      .team-person {
        width: auto;
        padding: 20px 0;
        box-sizing: border-box;
        height: auto;
        img {
          width: 130px;
          height: 130px;
        }
      }
    }

    .faq-item {
      gap: 20px;
      padding-top: 20px;
    }
  }

  &-blockby {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
    align-items: center;
    gap: 10px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    img {
      display: block;
      flex-shrink: 1;
      width: 80px;
      height: 23px;
    }
  }

  .team-person {
    border: 1px solid #beb6ae;
    background: rgba(225, 224, 224, 0.1);
    backdrop-filter: blur(10px);
    flex-grow: 1;
    height: 451px;
    padding: 50px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: rgba(117, 113, 103, 0.1);
    }
    .person-name {
      margin-top: 25px;
      color: #3d4128;
      font-family: Francesco;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 90%; /* 28.8px */
    }
    .person-pos {
      color: #757167;
      font-family: Francesco;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 90%;
      margin: 20px 0;
    }
  }
}

.faqmm {
  gap: 0 !important;
  flex-direction: column;
}
