.Toastify__toast-container {
  padding: 0px;
  // width: 370px;
  width: auto;
  min-width: 370px;
  border-radius: 16px;
  .Toastify__toast {
    max-width: 616px;
    min-height: 80px;
    height: 100%;
    margin-bottom: 0px;
    padding: 0px 10px 0 0px;
    background: linear-gradient(
      to right,
      #000 0,
      #000 80px,
      #e1e0e0 80px,
      #e1e0e0 100%
    );
    box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    overflow: hidden;
    .Toastify__toast-body {
      font-family: Francesco;
      .toast-status-title {
        display: flex;
        align-items: center;
        &_title {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          flex: 1 1;
          margin-left: 40px;
        }
        svg {
          margin-left: 24px;
          width: 24px;
          height: 24px;
        }
        .tip-close {
          display: flex;
          align-items: center;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .fail-content {
        color: #3D4128;
      }
      .success-content {
        color: #3d4028;
      }

      .toast-tx-info {
        margin-top: 10px;
        height: 33px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        font-size: 12px;
        color: var(--text-color-80);
        &:hover {
          background: var(--active-color-8);
        }
        &_view {
          color: #4a3de6;
        }
      }
      .toast-tx-info-des {
        margin-top: 10px;
        padding: 0 8px;
        color: var(--text-color-80);
      }
    }
  }
}
.Toastify__toast-container--top-center {
  top: 30px;
  right: 24px;
  left: auto;
  transform: none;
}
@media screen and (max-width: 704px) {
  .Toastify__toast-container {
    // width: 345px;
    width: auto;
    min-width: 345px;
  }
  .Toastify__toast-container--top-center {
    right: 8px;
    width: calc(100% - 16px);
    word-break: break-all;
  }
}
