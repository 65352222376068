@import "./home/index.scss";
@import "./mint/index.scss";
@import "./reedeem/index.scss";
@import "./intro/index.scss";
@import "./introduce/index.scss";

@font-face {
  font-family: "Francesco";
  src: url("../assets/francesco.otf") format("opentype");
}
