.page-header {
  width: 100%;
  padding: 20px 30px 0;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  flex: 0 0 auto;
  .page-header-logo-icon {
    svg {
      width: 100px;
      height: 50px;
    }
  }
  // position: sticky;
  // z-index: 2;
  // top: 0;

  &-mobile {
    .page-header-logo-icon {
      svg {
        width: 76px;
        height: 40px;
      }
    }
  }

  .page-header-logo {
    display: flex;
    align-items: center;
    &.intro-svg {
      svg {
        fill: #fff !important;
      }
    }
    .page-header-search {
      margin-left: 50px;
    }
    .page-header-logo-icon {
      cursor: pointer;
      &.intro {
        svg {
          // color: #fff;
        }
      }
    }
  }

  .page-header-right {
    display: flex;
    align-items: center;
  }

  .scroll-chain-logo {
    display: flex;
    height: 34px;
    padding: 8px 20px 8px 3px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 40px;
    margin-right: 10px;
    background: #202023;
    user-select: none;

    span {
      margin-left: 10px;
      color: #b6bbb4;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.wallet-modal {
  width: 500px;
  border-radius: 24px;
  padding: 40px;
  box-sizing: border-box;
  &.mobile {
    width: 80vw;
    margin-left: 10vw;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .header-title {
    font-size: 24px;
    font-weight: 600;
    color: #0e0e0f;
  }
  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 0;
  }
  .wallet-item {
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 20px;
    background: #f0f1f2;

    &:hover {
      background: #e1e2e5;
    }

    &_title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_left {
        display: flex;
        align-items: center;
      }

      &_loading {
        position: relative;
        width: 14px;
        height: 14px;
        margin-right: 3px;
        border: 2px solid #5bc781;
        border-bottom-color: #e1e2e5;
        border-radius: 100%;
        animation: turn infinite 1s linear;
      }

      @keyframes turn {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    &_logo {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background: #fff;
    }

    &_name {
      margin-left: 10px;
      font-size: 18px;
      font-weight: 600;
    }

    &_des {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      padding: 5px 10px 5px 5px;
      border-radius: 10px;
      background: #efdada;

      &_left {
        display: flex;
        align-items: center;

        span {
          white-space: nowrap;
          display: flex;
          padding: 3px 10px;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          color: #fff;
          font-size: 12px;
          font-weight: 400;
          background: linear-gradient(
              135deg,
              #f2b074 0%,
              #db6f61 40.75%,
              #a53cf2 100%
            ),
            #d35454;
        }

        p {
          margin: 0 0 0 10px;
          color: #c14343;
          font-size: 12px;
          font-weight: 400;
        }
      }

      &_right {
        cursor: pointer;
        color: #4a3de6;
        font-size: 12px;
        font-weight: 500;
        text-decoration-line: underline;
      }
    }

    &_uninjected {
      padding: 5px 10px;
      border-radius: 8px;
      background: #fff;
      font-size: 14px;
      color: #676968;
    }
  }
}
.wallet-logout-modal {
  position: absolute;
  width: 390px;
  top: 68px;
  right: 30px;
  margin: unset;
  border: 1px solid var(--light-line-line-100, rgba(139, 138, 153, 0.1));
  box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.04);
  border-radius: 30px;
  padding: 0;
  background-color: #ffffff;
  overflow: hidden;
  z-index: 100000;

  &.mobile {
    width: 90vw;
    left: 5vw;
    right: auto;
  }

  &_wallet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: #f0f1f2;

    &_info {
      display: flex;
      align-items: center;
      height: 54px;

      svg {
        width: 30px;
        height: 30px;
      }

      span {
        margin-left: 10px;
        color: #0e0e0f;
        font-size: 14px;
        font-weight: 400;
      }
    }
    &_logout {
      display: flex;
      height: 34px;
      box-sizing: border-box;
      align-items: center;
      padding: 8px 20px;
      justify-content: center;
      gap: 5px;
      cursor: pointer;
      border-radius: 40px;
      background: #0e0e0f;
      color: #f0f1f2;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .wallet-logout-modal_wallet-address {
    min-height: 66px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;

    .wallet-logout-modal_wallet-address-logo {
      width: 30px;
      height: 30px;
      border-radius: 8px;
      overflow: hidden;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .wallet-logout-modal_wallet-address-val {
      color: #2f302f;
      font-size: 14px;
      font-weight: 500;
      margin: 0 10px;
    }

    .wallet-logout-modal_wallet-address-copy {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 4px;

      svg {
        width: 14px;
        height: 14px;
      }

      &:hover {
        background: rgba(61, 61, 68, 0.08);
      }
    }
  }
}

.user-language-modal {
  position: absolute;
  width: max-content;
  top: 68px;
  right: 30px;
  margin: unset;
  border: 1px solid var(--light-line-line-100, rgba(139, 138, 153, 0.1));
  box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 8px;
  background-color: #ffffff;
  overflow: hidden;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    box-sizing: border-box;
    color: #0e0e0f;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
    cursor: pointer;
    padding: 8px;

    &:hover {
      background: #f0f1f2;
    }
  }
}

.my-domain-btn,
.white-list-btn {
  &.mobile {
    border-radius: 80px;
    height: 44px;
    line-height: 44px;
    &:hover {
      background: rgba(215, 211, 209, 0.6);
      backdrop-filter: blur(10px);
    }
  }
  color: #3d4028;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 90%; /* 12.6px */
  padding: 0px 20px;
  cursor: pointer;
  &:hover {
    color: #3d4028;
  }

  &.is-home {
    color: #e1e0df;
  }
}
.user-button {
  display: flex;
  font-family: sans-serif;
  height: 44px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  background-color: #000;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  svg {
    margin-left: 10px;
  }
}

.user-language {
  margin-left: 10px;
  display: flex;
  height: 34px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  color: #b3b4c8;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 40px;
  box-sizing: border-box;
  background-color: #202023;

  span {
    margin: 0 5px;
  }
}

.menu-home {
  path {
    fill: #e1e0df;
  }
}
