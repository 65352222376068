.reedeem-modal {
  border-radius: 10px;
  background: #ecebeb;
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.mobile {
    padding: 30px;
    .reedeem-modal-title {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .reedeem-label {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .reedeem-modal-input {
      margin-top: 5px;
      input {
        height: 24px;
        font-size: 14px;
      }
    }
    .reedeem-button-area {
      margin-top: 15px!important;
      .btn-div {
        height: 32px!important;
        font-size: 14px!important;
      }
    }

  }

  &-title {
    color: #3e4028;
    font-family: Francesco;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    line-height: 90%; /* 28.8px */
  }

  .reedeem-label {
    color: #757167;
    font-family: Francesco;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 10px;
  }

  .reedeem-modal-input {
    margin-top: 10px;
    input {
      font-family: Francesco;
      width: 100%;
      height: 36px;
      font-size: 20px;
      border: 1px solid #beb6ae;
      border-radius: 4px;
      // padding: 0 10px;
    }
  }

  .input-grid-wrapper {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .reedeem-button-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin-top: 30px;

    .btn-div {
      cursor: pointer;
      color: #181817;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
      display: flex;
      justify-content: center;
      border-radius: 80px;
      align-items: center;
      flex-grow: 1;
      height: 40px;
      border: 1px solid #181817;

      &.reedeem-modal-confirm-btn {
        background-color: #181817;
        color: #e1e0e0;
      }
    }

    button {
      outline: none;
    }
  }
}
