.page-footer {
  width: 100vw;
  margin-top: 120px;
  position: relative;
  // height: 95px;
  flex: 0 0 auto;
  padding: 20px 0;
  position: relative;
  background: #181817;
  box-sizing: border-box;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;

  .footer-desc {
    width: 311px;
    color: #757167;
    margin-left: 20px;
    font-family: Francesco;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-top: 66px;
    &.mobile {
      width: auto;
      margin-left: 20px;
      margin-top: -8px;
    }
    &.pc {
      margin-left: calc(50vw - 700px);
      margin-right: calc(50vw - 700px);
    }
  }

  .page-footer-top {
    margin-left: 20px;
    display: flex;
    height: auto;
    justify-content: center;
    padding-bottom: 19px;
    box-sizing: border-box;
    &.pc {
      margin-left: calc(50vw - 700px);
      margin-right: calc(50vw - 700px);
    }
    &.mobile {
      margin-left: 20px;
    }
    // border-bottom: 1px solid rgba(81, 81, 92, 0.4);

    .page-footer-icon-box {
      width: max-content;
      display: flex;
      height: 40px;

      div {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 10px;

        svg {
          path {
            fill: rgba(255, 255, 255, 1);
          }
        }

        &:hover {
          background-color: rgba(245, 247, 253, 0.12);

          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }

      div:last-child {
        margin-right: 0;
      }
    }
  }

  .page-footer-bottom {
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px 0 0;

    div {
      height: 15px;
      color: #676968;
      font-size: 12px;
      font-weight: 400;
      margin-left: 30px;

      &:hover {
        color: #b6bbb4;
      }
    }
  }
}
