.mint-page {
  .mint-title {
    &.pc {
      padding: 0 calc(50vw - 700px);
    }
    padding: 0 20px;
    color: #3e4028;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    margin-top: 40px;
    &.mobile {
      width: 100vw;
      box-sizing: border-box;
      padding: 0 20px;
    }
  }
  .mint-desc {
    &.pc {
      padding: 0 calc(50vw - 700px);
    }
    padding: 0 20px;
    margin-top: 20px;
    color: #757167;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    &.mobile {
      width: 100vw;
      box-sizing: border-box;
      padding: 0 20px;
    }
  }
  .mint-main {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    border-top: 1px solid #beb6ae;
    border-bottom: 1px solid #beb6ae;
    .supply {
      text-align: center;
      padding: 5px 0;
      background: #000;
      color: #d7d3d1;
      margin-top: -5px;
    }
    &.mobile {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      .mint-main-left {
        .wrapper {
          .mint-left-title {
            margin-top: 20px;
          }
          width: 100vw;
          box-sizing: border-box;
          padding: 0 20px;
        }
      }
      .mint-input {
        width: 100%;

        > input {
          width: 100%;
        }

        > img {
          width: 44px;
          height: 44px;
        }
      }

      .mint-main-wrapper {
        button {
          width: 100%;
        }
      }

      .mint-main-right {
        width: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &-left {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px 0;
      flex: 1;
      .wrapper {
        width: 480px;
        .mint-left-title {
          color: #3e4028;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 90%;
          margin-bottom: 20px;
        }
        .mint-left-desc {
          color: #757167;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
      }
    }
  }

  .mint-input-title {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    color: #757167;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    margin-bottom: 20px;
  }

  .mint-input {
    width: 480px;
    height: 60px;
    display: flex;
    justify-content: center;
    border-radius: 30px;
    position: relative;
    border: 1px solid #757167;
    background: #d7d3d1;
    backdrop-filter: blur(5px);
    > img {
      border-radius: 50%;
      position: absolute;
      background-color: #3e4028;
      left: 10px;
      top: 8px;
    }
    .right {
      left: unset;
      right: 10px;
    }
    > input {
      width: calc(100% - 30px);
      border: none;
      text-align: center;
      background-color: transparent;
      font-size: 24px;
      &:focus-visible {
        outline: none;
        color: #3d4128;
        font-style: normal;
        font-weight: 400;
        line-height: 90%;
      }
    }
  }

  .mint-main-wrapper {
    color: #757167;
    font-family: Francesco;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;

    button {
      display: flex;
      width: 480px;
      padding: 22px 70px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      border-radius: 80px;
      background: #181817;
      color: #fff;
      margin-top: 20px;
      margin-bottom: 20px;
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;

      &.disabled {
        opacity: 0.3;
      }

      &.loading {
        background: rgba(24, 24, 23, 0.7);
      }
    }
  }
}

@keyframes turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mintLoading {
  display: flex;
  align-items: center;
  > svg {
    animation: turn infinite 1s linear;
  }
}

.mint-input-img-dis {
  opacity: 0.3;
  cursor: not-allowed;
}
