.intro {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  .playIncon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .startBtnWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 80px;
    right: 100px;
    color: #e1e0e0;
    gap: 10px;
    &.mobile {
      right: 50%;
      transform: translateX(50%);
    }
    > button {
      font-family: Francesco;
      cursor: pointer;
      border: none;
      appearance: none;
      font-size: 20px;
      background-color: transparent;
      outline: none;
      color: #e1e0e0;
      padding: 15px 0 15px 40px;
      border-radius: 80px;
      text-decoration: underline;
    }
  }
}
