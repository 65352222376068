@import './base/color.scss';
@import './base/colors.d.scss';
@import './base/mixin-theme.scss';
@import './component.scss';
@import './base/ReactToastify.scss';
@import './base/breakpoints.scss';
@import './base/tx-status.scss';

:root {
    --line-color-100: #{line-color("default-100")};
    --line-color-200: #{line-color("default-200")};

    --text-color-50: #{text-color("default-50")};
    --text-color-80: #{text-color("default-80")};
    --text-color-100: #{text-color("default-100")};
    --text-color-200: #{text-color("default-200")};

    --bg-color-400: #{bg-color("default-400")};
    --bg-color-300: #{bg-color("default-300")};
    --bg-color-200: #{bg-color("default-200")};
    --bg-color-100: #{bg-color("default-100")};
    --bg-color-500: #{bg-color("default-500")};
    
    --bg-color-300-light: #{bg-color("default-300")};
    --bg-color-300-dark: #{bg-color("dark-300")};

    --active-color-8: #{active-color("default-8")};
    --active-color-12: #{active-color("default-12")};
    --active-color-50: #{active-color("default-50")};

    --primary-normal: #4a3de6;
    --primary-100: #4a3de6;
    --primary-10: #695eea1a;
    --primary-normal-16: #4136ca;
    --primary-normal-18: #3e33c1;

    --rt-color-dark:  #{text-color("default-200")};
    --rt-text-color: #{bg-color("default-100")};

    --bg-tip: #{text-color("default-200")};
    --bg-tip-text-color: #{bg-color("default-100")};
}

:root[dark-background='dark'] {
    --line-color-100: #{line-color("dark-100")};
    --line-color-200: #{line-color("dark-200")};

    --text-color-50: #{text-color("dark-50")};
    --text-color-80: #{text-color("dark-80")};
    --text-color-100: #{text-color("dark-100")};
    --text-color-200: #{text-color("dark-200")};

    --bg-color-400: #{bg-color("dark-400")};
    --bg-color-300: #{bg-color("dark-300")};
    --bg-color-200: #{bg-color("dark-200")};
    --bg-color-100: #{bg-color("dark-100")};
    --bg-color-500: #{bg-color("dark-500")};

    --active-color-8: #{active-color("dark-8")};
    --active-color-12: #{active-color("dark-12")};
    --active-color-50: #{active-color("dark-50")};

    --primary-normal: #4a3de6;
    --primary-100: #4a3de6;
    --primary-normal-16: #4136ca;
    --primary-normal-18: #3e33c1;
    
    --rt-color-dark: #{text-color("dark-200")};
    --rt-text-color: #{bg-color("dark-100")};

    --bg-tip: #{text-color("dark-200")};
    --bg-tip-text-color: #{bg-color("dark-100")};
}