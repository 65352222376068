.status-pending {
  color:#F89753
}
.status-success {
  color:#69D4C1
}
.status-error {
  color:#EB5151
}
.status-cancel {
  color: var(--text-color-80);
}