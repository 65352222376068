.introduce {
  width: 100vw;
  padding-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 20px;
    cursor: pointer;
    border: none;
    appearance: none;
    outline: none;
    background: #181816;
    color: #fff;
    padding: 15px 40px;
    border-radius: 80px;
    transform: none;
    margin-bottom: 20px;
  }

  .wrapper {
    margin-top: 54px;
    width: 1020px;
    display: flex;
    gap: 40px;
    justify-content: space-between;
    align-items: flex-start;

    &-mobile {
      width: 340px;
      flex-direction: column;
    }

    .left {
      border-radius: 10px;
      flex-grow: 1;
      background: #ecebeb;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
      padding: 40px;
      .hh {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        &-mobile {
          flex-direction: column;
          gap: 20px;
        }

        .title {
          color: #757167;
          font-family: Francesco;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: left;
        }
      }
      .detail {
        > div {
          display: flex;
          justify-content: space-between;
          color: #000;
          font-family: Francesco;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          width: 260px;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;

      .pp {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.mobile {
          top: calc(50% + 25px);
        }
      }

      &-mobile {
        flex-direction: column-reverse;
        .text {
          padding-top: 20px;
          margin-top: -10px;
          border-top: 1px solid rgba(117, 113, 103, 0.6);
        }
      }
    }

    .box {
      background: url("https://juhnz.web3app.vip/images/box.png") center
        no-repeat;
      background-size: cover;
      width: 100%;
      height: 260px;
      margin: 0 auto;
      border-radius: 12px;
    }
  }

  video {
    background-color: #000;
    border-radius: 8px;
  }

  .text {
    color: #757167;
    text-align: center;
    font-family: Francesco;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
