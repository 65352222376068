@import "./style/theme.scss";
@import "./pages/pages.scss";
@import "./components/index.scss";

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: "Francesco", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #3e4028;
  background-color: #e1e0df;
}
reach-portal {
  z-index: 2;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: max-content;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.root-box {
  width: 100%;
  min-height: 100vh;
  position: relative;
  transition: 1s color;
  .root-center {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background: url("https://juhnz.web3app.vip/images/jz-bg.png") no-repeat;
    background-position: right 0px top 0px;
    background-size: 70vw 49.56vw;
    // background-size: 100% 100%;
    // box-sizing: border-box;
  }
}

.root-box-other-pages {
  .root-center {
    background-color: #e1e1e1;
    background-image: url("https://juhnz.web3app.vip/images/bg1.png");
    background-position: 0px 0px;
    background-size: contain;

    &.mobile {
      background-image: url("https://juhnz.web3app.vip/images/bg4.png");
      background-position: 0px 200vw;
    }
  }

  &::after {
    background-color: transparent;
  }
}

// .intro {
//   .root-center {
//     background-image: url("../public/images/intro.jpeg");
//     width: 100vw;
//     height: 100vh;
//   }
// }

.root-box-hide-after {
  &::after {
    background-color: transparent;
  }
}

input {
  color: var(--text-color-100);
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.svg-wrapper {
  display: flex;
  align-items: center;
  svg path {
    fill: var(--text-color-100);
  }
}

.svg-stroke-wrapper {
  display: flex;
  align-items: center;
  svg path {
    stroke: var(--text-color-100);
  }
}
.svg-des-wrapper {
  display: flex;
  align-items: center;
  svg path {
    fill: var(--text-color-50);
  }
}
.mir-svg-fill {
  fill: var(--text-color-100);
}
.mir-svg-stroke {
  stroke: var(--text-color-100);
}
.mir-des-svg-stroke {
  stroke: var(--text-color-100);
}
.flex-align-center {
  display: flex;
  align-items: center;
}

.wallet-logout-modal {
  color: #181616;
}
.wallet-modal {
  color: #181616;
}

.page-common-content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  background-image: url("https://juhnz.web3app.vip/images/bg2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center 2000px;

  &.mobile {
    background-position: center 3400px;
    background-image: url("https://juhnz.web3app.vip/images/bg5.png");
  }
}
.btn-loading,
.btn-disable {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.btn-loading-icon {
  position: relative;
  width: 14px;
  height: 14px;
  border: 2px solid #5bc781;
  border-bottom-color: #e1e2e5;
  border-radius: 100%;
  animation: turn infinite 1s linear;
  margin-right: 8px;
}

.blind-box {
  position: fixed;
  top: 130px;
  right: 80px;
  z-index: 1;
  width: 148px;
  height: 176px;
  border-radius: 30px;
  background: #d1e67d;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;

  .blind-box-logo {
    width: 128px;
    height: 128px;
    border-radius: 20px;
    overflow: hidden;
    margin: 0 auto;
    background-color: #0e0e0f;

    svg {
      width: 128px;
      height: 128px;
    }
  }

  span {
    display: block;
    text-align: center;
    padding-top: 10px;
    color: #43532a;
    font-size: 12px;
    font-weight: 600;
  }
}
