.page-my {
  width: 100%;
  padding: 0 20px;

  &.pc {
    padding: 0 calc(50vw - 700px);
  }

  &.mobile {
    box-sizing: border-box;
    padding: 0 20px;
    .page-my-title {
      margin-top: 20px;
    }
    .itemWrapper {
      display: grid;
      border: none;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      .mint-item {
        border: 1px solid #beb6ae;
        width: auto;
        padding: 10px;
        &.selected {
          border: 1px solid #000;
        }
        img {
          width: 100%;
          height: 150px;
        }
      }
    }
  }

  .title-part {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
    color: #3d4128;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
  }

  .status-part {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #3d4128;
    color: #757167;
    font-family: Francesco;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .rd-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    button {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      padding: 22px 70px;
      gap: 10px;
      border-radius: 80px;
      background: #181817;
      color: #fff;
      appearance: none;
      outline: none;
      border: none;

      &.dis {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
  &-title {
    margin-top: 80px;
    color: #3d4128;
    font-family: Francesco;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    display: flex;
    align-items: center;
    svg {
      margin-left: 20px;
    }
  }

  .reedeem-title {
    margin-top: 40px;
    color: #3e4028;
    font-family: Francesco;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    text-align: center;
  }

  .reedeem-desc {
    margin-top: 20px;
    color: #757167;
    text-align: center;
    font-family: Francesco;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
    margin-bottom: 50px;
  }

  .itemWrapper {
    display: flex;
    border: 1px solid #beb6ae;
    overflow-x: scroll;
    justify-content: center;
  }

  .mint-item {
    width: 360px;
    box-sizing: border-box;
    padding: 20px;
    cursor: pointer;
    &:hover {
      background: #d7d3d1;
      backdrop-filter: blur(5px);
    }
    &.selected {
      border: 2px solid #181817;
    }

    &.not-found {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.not-found:hover {
      background: unset;
      backdrop-filter: none;
      cursor: unset;
    }
  }
}

.rotate {
  svg {
    animation: rrr 1s linear 0s infinite;
  }
}

@keyframes rrr {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
